import * as React from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import GoogleLogo from "../../assets/Google_logo.svg";
import GuardianLogo from "../../assets/Guardian_logo.svg";
import CentralLogo from "../../assets/integration_illustration.svg";
import { ToastContainer } from "react-toastify";
import { successNotify, errorNotify } from "../../components/ToastComponent";
import {
  isGuardianUser,
  isManagerOrSuperUser,
} from "../../utils/common_functions";

const LoginPage = () => {
  const invalid_response_with_no_body = [500];
  const setLocalStorageValues = (dataFromApi) => {
    for (const [key, value] of Object.entries(dataFromApi)) {
      if (key === "name") {
        localStorage.setItem("user_name", value);
      } else if (key === "picture") {
        localStorage.setItem("user_photo", value);
      } else if (key === "email") {
        localStorage.setItem("user_email", value);
      } else if (key === "token") {
        localStorage.setItem("access_token", value);
      } else if (key === "is_superuser") {
        localStorage.setItem("is_superuser", value);
      } else {
        localStorage.setItem(key, value);
      }
    }
  };

  const redirectToLandingPage = () => {
    if (!isGuardianUser() || (isGuardianUser() && !isManagerOrSuperUser())) {
      successNotify("Login Successful. Redirecting to Ticket Summary");
      setTimeout(() => {
        window.location.href = `/tickets`;
      }, 1000);
    } else {
      let urlPath = window.location.href.split("/");
      let page = urlPath[urlPath.length - 1];
      successNotify("Login Successful. Redirecting to Ticket Metrics Dashboard");
      setTimeout(() => {
        window.location.href = `${page}` ? `${page}` : "/dashboard";
      }, 1000);
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    const dataToSend = {
      code: credentialResponse.code,
    };
    const newStringData = JSON.stringify(dataToSend);
    let LOGIN_URL = `${baseURL}v1/auth/google-login`;
    const response = await fetch(LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: newStringData,
    });

    const dataFromApi = await response.json();

    if (!response.ok && !("name" in dataFromApi)) {
      const error_message = invalid_response_with_no_body.includes(
        response.status
      )
        ? "Encountered server issue, please contact admin"
        : dataFromApi;
      errorNotify(error_message);
    } else {
      setLocalStorageValues(dataFromApi);
      redirectToLandingPage();
    }
  };

  const googleLoginButtonClick = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (tokenResponse) => handleGoogleLogin(tokenResponse),
  });

  return (
    <Box sx={{ backgroundColor: "#fff", height: "100vh" }}>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            background:
              "linear-gradient(135deg, rgba(244, 238, 252, 0.80) 0%, rgba(250, 238, 236, 0.80) 100%)",
          }}
        >
          <Box sx={{ padding: "1.5vw" }}>
            <img src={GuardianLogo} loading="lazy" alt="guardian_logo" />
          </Box>
          <Box sx={{ margin: "auto" }}>
            <img
              src={CentralLogo}
              loading="lazy"
              alt="google_logo"
              style={{ width: "auto" }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "0 20px", md: "6rem 0 0 0" },
          }}
        >
          <Box sx={{ textAlign: "center", marginBottom: { xs: 3, md: 0 } }}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ color: "#3c3e49", fontWeight: 500 }}
            >
              Welcome to Guardian 👋
            </Typography>
          </Box>
          <Button
            id="my-signin2"
            onClick={googleLoginButtonClick}
            variant="outlined"
            sx={{
              padding: "0.5rem",
              marginTop: "40px",
              borderRadius: "6px",
              backgroundColor: "#FFF",
              border: "1px solid #D9C4FF",
              "&:hover": {
                backgroundColor: "#FFF",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                border: "1px solid #6200ea",
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="span"
              sx={{ display: "flex", gap: "16px", alignItems: "center" }}
            >
              <img
                loading="lazy"
                src={GoogleLogo}
                alt="Google Logo"
                style={{ width: "20px" }}
              />
              <Typography
                variant="body1"
                component="b"
                sx={{ color: "#262626", fontWeight: 500 }}
              >
                Continue with Google
              </Typography>
            </Box>
          </Button>
        </Grid>
      </Grid>
      <ToastContainer autoClose={1000} />
    </Box>
  );
};

export default LoginPage;
